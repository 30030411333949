import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';

function ShortcodesTable({ shortcodes }) {
  
  // Initialize state for the search input
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredShortcodes, setFilteredShortcodes] = useState(shortcodes);
  const itemsPerPage = 10; // Number of items to display per page

  // Calculate the number of pages
  const pageCount = Math.ceil(shortcodes.length / itemsPerPage);

  useEffect(() => {
    if (search.length >= 2) {
      console.log("S > 2");
      // Фильтруем данные
      const filteredData = shortcodes.filter(shortcode => {
        const nameMatch = shortcode.name.toLowerCase().includes(search.toLowerCase());
        const urlNameMatch = shortcode.url_name.toLowerCase().includes(search.toLowerCase());
        return nameMatch || urlNameMatch;
      });

      // Обновляем состояние с отфильтрованными данными
      setFilteredShortcodes(filteredData);
    } else {
      // Если строка поиска короче 2 символов, используем исходные данные
      setFilteredShortcodes(shortcodes);
    }
  }, [search, shortcodes]);

  // Calculate the slice of shortcodes to display for the current page
  const offset = currentPage * itemsPerPage;
  const shortcodesToDisplay = filteredShortcodes.slice(offset, offset + itemsPerPage);

  const highlightMatch = (text) => {
    if (search && text) {
      const regex = new RegExp(`(${search})`, 'gi');
      return text.split(regex).map((part, index) =>
        regex.test(part) ? (
          <span key={index} style={{ backgroundColor: '#fff27c' }}>
            {part}
          </span>
        ) : (
          part
        )
      );
    }
    return text;
  };

  const copyUrl = (url, div) => {
    const tempInput = document.createElement('input');
    tempInput.value = url;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    // Add the copy-animation class to create the animation effect
    div.classList.add('copy-animation');

    toastr.info("Shortcode copied to clipboard", "Success", {"timeOut": "1500"});
    // Remove the copy-animation class and hide the tooltip after a delay
    setTimeout(() => {
      div.classList.remove('copy-animation');
    }, 1000); // Adjust the delay as needed
  };

  useEffect(() => {
    // Perform any initial data fetching or AJAX requests here
  }, []);

  // Event handler for changing the page
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this shortcode?')) {
    fetch(`/shortcodes/${id}`, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response, e.g., set a flag that the shortcode has been deleted
        console.log(data.success);
        if (data.success) {
          // get current url
          let url = window.location.href;
          // reload current page
          window.location.replace(url);
        }
      })
      .catch((error) => {
        console.error('Error deleting shortcode:', error);
      });
    };
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      hour12: false,
    };
  
    return `${date.toLocaleString('en', options)}`;
  }

  return (
    <div className="box-item">
      {/* Autocomplete search input */}
      <input
        type="text"
        placeholder="Search..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="form-control"
      />
    
      {search.length >= 2 ? (
        <div>
          <table className="table">
            <thead>
              <tr>
                <th>Описание</th>
                <th className="middle">Shortcode</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredShortcodes.map((shortcode) => (
                <tr key={shortcode.id} className="banner-tr">
                  <td className="middle">{highlightMatch(shortcode.name)}</td>
                  <td style={{ width: '100%', position: 'relative' }}>
                    <div
                      className="form-control border-0"
                      style={{ backgroundColor: '#e9ecef', cursor: 'pointer' }}
                      onClick={(e) => copyUrl(`https://paripesa.bet/${shortcode.url_name}`, e.currentTarget)}
                    >
                      {highlightMatch(`https://paripesa.bet/${shortcode.url_name}`)}
                    </div>
                  </td>

                  <td>
                    <a href={`/shortcodes/${shortcode.id}/edit`} className="btn btn-primary">
                      <i className="fa fa-pencil"></i>
                    </a>
                  </td>
                  <td>
                    <a
                      onClick={ () => handleDelete(shortcode.id) }
                      className="btn btn-danger"
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          <table className="table">
            <thead>
              <tr>
                <th>Описание</th>
                <th className="middle">Shortcode</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {shortcodesToDisplay.map((shortcode) => (
                <tr key={shortcode.id} className="banner-tr">
                  <td className="middle">
                    <div>{highlightMatch(shortcode.name)}</div>
                    <div className="creation-date">
                      Created at: {formatTimestamp(shortcode.created_at)}
                    </div>
                  </td>
                  <td style={{ width: '100%', position: 'relative' }}>
                    <div
                      className="form-control border-0"
                      style={{ backgroundColor: '#e9ecef', cursor: 'pointer' }}
                      onClick={(e) => copyUrl(`https://paripesa.bet/${shortcode.url_name}`, e.currentTarget)}
                    >
                      {highlightMatch(`https://paripesa.bet/${shortcode.url_name}`)}
                    </div>
                  </td>

                  <td>
                    <a href={`/shortcodes/${shortcode.id}/edit`} className="btn btn-primary">
                      <i className="fa fa-pencil"></i>
                    </a>
                  </td>
                  <td>
                    <a href={`/shortcodes/${shortcode.id}/stats`} className="btn btn-primary">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                    </a>
                  </td>
                  <td>
                    <a
                      onClick={ () => handleDelete(shortcode.id) }
                      className="btn btn-danger"
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination" // Custom class for the container
            previousClassName="page-link" // Bootstrap class for previous button
            nextClassName="page-link" // Bootstrap class for next button
            pageClassName="page-item" // Bootstrap class for page numbers
            pageLinkClassName="page-link" // Bootstrap class for page number links
            activeClassName="active" // Bootstrap class for the active page
            disabledClassName="disabled" // Bootstrap class for disabled pages
            // style for break
            breakClassName="page-link"
          />
        </div>
      )
      }
    </div>
  )
}

export default ShortcodesTable;
